<template>
  <div>
    <div class="row" v-if="data != ''">
      <div class="d-flex"
           :class="col"
           v-for="moderator in data"
           :key="moderator.id">
        <div class="card">
          <!-- Educator Photo -->
          <img class="educator-photo"
               :src="moderator.profile_img_url"
               :alt="moderator.id + '-photo'"
               v-if="moderator.profile_img_url" />

          <img class="educator-photo"
               src="../../../assets/images/sample_user_profile.png"
               :alt="moderator.id + '-photo'"
               v-else />

          <div class="container pt-2 pb-2">
            <!-- Educator Info -->
            <div>
              <p class="mb-0 small">
                {{ moderator.firstname }} {{ moderator.lastname }}
              </p>
              <p class="mb-0 small">
                {{ moderator.grade_level | levelAbbreviation }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col text-center">
        <p class="mt-2">
          No Moderators
        </p>
      </div>
    </div>
    <div class="row"
          v-if="educatorsCurrentPage !== educatorsLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue
                          text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreEducators">
          Load More
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name  : 'ModeratorCards',
    props : {
      data                 : Array,
      colSize              : Number,
      educatorsCurrentPage : Number,
      educatorsLastPage    : Number,
    },
    computed : {
      col() {
        return 'col-' + this.colSize;
      },
    },
    methods : {
      
      /**
       * Load more educators
       */

      loadMoreEducators() {
        this.$emit('more');
      },
    },
    mixins : [
      FilterMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/moderator/moderator-cards";
</style>
